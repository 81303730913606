import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import { MappedAccount, SongwhipAccount, AccountUser } from '../../types';

export const mapAccount = ({
  id,
  name,
  users,
  customDomains,
  orchardBrand,
  config,
}: SongwhipAccount): MappedAccount => {
  return {
    id,
    name,
    config,

    users: users?.map((accountUser) =>
      removeUndefinedKeys<AccountUser>({
        id: accountUser.id,
        name: accountUser.name,
        email: accountUser.email,
        image: accountUser.image,
        isAccountAdmin: accountUser.role === 'admin',
        joinedAtTimestamp: new Date(accountUser.joinedAt).getTime(),
      })
    ),

    customDomains,
    orchardBrand: orchardBrand ?? undefined,
  };
};
