import songwhipApi, { SongwhipApiOptions } from '../songwhipApi';

import {
  SongwhipArtist,
  SongwhipAccount,
  SongwhipUser,
  SongwhipAlbum,
  SongwhipTrack,
} from '../types';

import { mapUser } from '../users';
import mapAlbum from '../mapper/mapAlbum';
import mapTrack from '../mapper/mapTrack';
import mapArtist from '../mapper/mapArtist';

/**
 * WARN: this will 403 if the account has no artist credits causing
 * force user to /logout page. Use `logoutOnForbidden: false` if you
 * want to override this behavior.
 */
export const addArtistToAccountApi = async ({
  accountId,
  artistId,
  ...songwhipApiParams
}: {
  accountId: number;
  artistId: number;
} & Pick<
  SongwhipApiOptions,
  'token' | 'logoutOnForbidden' | 'showToastOnError'
>) => {
  const {
    json: {
      data: { artist, user },
    },
  } = await songwhipApi<{
    artist: SongwhipArtist;
    account: SongwhipAccount;
    user?: SongwhipUser;
    isAlreadyOwner: boolean;
  }>(`accounts/${accountId}/artists`, {
    ...songwhipApiParams,

    method: 'POST',

    body: {
      artistId: Number(artistId),
    },
  });

  return {
    artist: mapArtist(artist),
    user: user && mapUser(user),
  };
};

export const addAlbumToAccountApi = async ({
  accountId,
  albumId,
  ...songwhipApiParams
}: {
  accountId: number;
  albumId: number;
} & Pick<
  SongwhipApiOptions,
  'token' | 'logoutOnForbidden' | 'showToastOnError'
>) => {
  const {
    json: {
      data: { album, user },
    },
  } = await songwhipApi<{
    album: SongwhipAlbum;
    account: SongwhipAccount;
    user: SongwhipUser;
    isAlreadyOwner: boolean;
  }>(`accounts/${accountId}/albums`, {
    ...songwhipApiParams,

    method: 'POST',

    body: {
      albumId,
    },
  });

  return {
    album: mapAlbum(album),
    user: mapUser(user),
  };
};

export const addTrackToAccountApi = async ({
  accountId,
  trackId,
  ...songwhipApiParams
}: {
  accountId: number;
  trackId: number;
} & Pick<
  SongwhipApiOptions,
  'token' | 'logoutOnForbidden' | 'showToastOnError'
>) => {
  const {
    json: {
      data: { track, user },
    },
  } = await songwhipApi<{
    track: SongwhipTrack;
    account: SongwhipAccount;
    user: SongwhipUser;
    isAlreadyOwner: boolean;
  }>(`accounts/${accountId}/tracks`, {
    ...songwhipApiParams,

    method: 'POST',

    body: {
      trackId,
    },
  });

  return {
    track: mapTrack(track),
    user: mapUser(user),
  };
};
