/* eslint-disable quote-props, object-shorthand */
/*
*   NOTE
*   This file is auto generated.
*   Any manual changes will be overwritten!
*/
import en from './en.json';
import ar from './ar.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import id from './id.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import ms from './ms.json';
import pl from './pl.json';
import pt from './pt.json';
import th from './th.json';
import tr from './tr.json';
import zhCN from './zh-CN.json';
import zhTW from './zh-TW.json';

const locales = {
    'en': en,
    'ar': ar,
    'de': de,
    'es': es,
    'fr': fr,
    'id': id,
    'it': it,
    'ja': ja,
    'ko': ko,
    'ms': ms,
    'pl': pl,
    'pt': pt,
    'th': th,
    'tr': tr,
    'zh-CN': zhCN,
    'zh-TW': zhTW,
};

export default locales;
