import fetchJson from '~/app/lib/fetchJson';
import songwhipApi, { SongwhipApiOptions } from '../songwhipApi';

import { mapUser } from './lib/mapUser';
import { SongwhipUser } from '../types';

export * from './lib/mapUser';

/**
 * Login to songwhip-api via songwhip-web proxy service that
 * manages setting `token` and `userId` cookie. This is a cleaner
 * separation than allowing songwhip-api to set cookies for the
 * songwhip-web domain.
 *
 * @deprecated
 */
export const loginGoogleApi = async (idToken: string) => {
  const {
    json: { data },
  } = await fetchJson('/api/login', {
    method: 'POST',

    body: {
      idToken,
    },
  });

  return {
    token: data.token,
    user: mapUser(data.user),
  };
};

export const loginOrchardApi = async ({
  idToken,
  accessToken,
  refreshToken,
  language,
  fetchFn,
}: {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  language: string;
  fetchFn?: SongwhipApiOptions['fetchFn'];
}) => {
  const {
    json: { data },
  } = await songwhipApi('users/login/orchard', {
    fetchFn,
    method: 'POST',

    body: {
      idToken,
      accessToken,
      refreshToken,
      language,
    },
  });

  return {
    token: data.token,
    user: mapUser(data.user),
  };
};

export const logoutApi = async () => {
  await fetchJson('/api/logout', {
    method: 'POST',
  });
};

export const getUserApi = async ({ userId }: { userId: string | number }) => {
  const {
    json: { data },
  } = await songwhipApi(`users/${userId}`);

  return mapUser(data.user);
};

export const patchUserConfigApi = (
  userId: number,
  patch: {
    emailOptOut?: {
      NEW_PRESAVE?: boolean;
    };
  }
) =>
  songwhipApi<{ user: SongwhipUser }>(`users/${userId}/config`, {
    method: 'PATCH',
    body: patch,
  });
