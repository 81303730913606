import { SongwhipUser } from '../../types';
import { MappedUser, UserAccount } from '../types';

export const mapUser = ({
  orchardBrand,
  orchardId,
  config,
  roles,
  accounts,
  ...rest
}: SongwhipUser): MappedUser => ({
  ...rest,
  isAdmin: roles.includes('admin'),
  isEmployee: roles.includes('employee'),
  config: config || undefined,
  orchardId: orchardId || undefined,
  orchardBrand: orchardBrand || undefined,

  accounts: accounts?.map(
    ({ id, name, config }): UserAccount => ({
      id,
      name,
      config,
    })
  ),
});
