import mapper, { Item } from '../mapper';
import songwhipApi from '../songwhipApi';
import { ItemTypes } from '~/types';
import { AddUserPageResult, SongwhipAccount } from '../types';
import { mapAccount } from '../accounts/lib/mapAccount';

const addUserPageApi = async ({
  userId,
  ...body
}: {
  userId: number;
  itemType: ItemTypes;
  itemId: number;
  dryRun?: boolean;
}) => {
  const {
    json: { data },
  } = await songwhipApi<AddUserPageResult<Item, SongwhipAccount>>(
    `users/${userId}/pages`,
    {
      method: 'POST',
      body,
      showToastOnError: false,
      logoutOnForbidden: false,
    }
  );

  if (!data.account) {
    throw new Error('`account` missing');
  }

  return {
    item: mapper(data.item),
    account: mapAccount(data.account),
  };
};

export default addUserPageApi;
