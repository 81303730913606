import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { fetchUserSuccess } from '../../session/actions';
import { Dispatch } from '../../types';
import { ItemTypes } from '~/types';

import {
  addAlbumToAccountApi,
  addArtistToAccountApi,
  addTrackToAccountApi,
} from '~/app/lib/songwhipApi/accounts/addItemToAccount';
import { TrackEvent } from '~/app/lib/tracker/useTracker';

const addItemToAccountAction =
  ({
    accountId,
    itemId,
    itemType,
    trackEvent,
  }: {
    accountId: number;
    itemType: ItemTypes;
    itemId: number;
    trackEvent: TrackEvent;
  }) =>
  async (dispatch: Dispatch) => {
    const { user, item } = await addItemToAccount({
      accountId,
      itemId,
      itemType,
      trackEvent,
    });

    dispatchFetchItemSuccess(dispatch, item);

    if (user) {
      dispatch(fetchUserSuccess(user));
    }
  };

const addItemToAccount = async ({
  itemId,
  itemType,
  accountId,
  trackEvent,
}: {
  itemType: ItemTypes;
  itemId: number;
  accountId: number;
  trackEvent: TrackEvent;
}) => {
  switch (itemType) {
    case ItemTypes.ARTIST: {
      const { user, artist } = await addArtistToAccountApi({
        accountId,
        artistId: itemId,
      });

      trackEvent({
        type: 'claim-artist',

        artistId: artist.id,
        artistPath: artist.path,
        artistName: artist.name,
      });

      return {
        item: artist,
        user,
      };
    }

    case ItemTypes.ALBUM: {
      const { user, album } = await addAlbumToAccountApi({
        accountId,
        albumId: itemId,
      });

      trackEvent({
        type: 'claim-album',

        albumId: album.id,
        albumName: album.name,
      });

      return {
        item: album,
        user,
      };
    }

    case ItemTypes.TRACK: {
      const { user, track } = await addTrackToAccountApi({
        accountId,
        trackId: itemId,
      });

      trackEvent({
        type: 'claim-track',

        trackId: track.id,
        trackName: track.name,
      });

      return {
        item: track,
        user,
      };
    }

    default: {
      throw new Error(`unexpected ItemType: ${itemType}`);
    }
  }
};

export default addItemToAccountAction;
